import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import FolderImage from '../../Assets/Images/folder.png'
import { Colors } from '../../Utils/theme'

interface ArchiveGridFolderProps {
  item: {
    uuid?: string
    nameFi?: string
    name?: string
    originalName?: string
    [key: string]: any
  }
  selected?: boolean
  onClick?: (event: React.MouseEvent) => void
  onDoubleClick?: (event: React.MouseEvent) => void
  'data-item-id'?: string
  multiRow?: boolean
}

export default function ArchiveGridFolder(props: ArchiveGridFolderProps) {
  const { 
    item, 
    selected = false, 
    onClick,
    onDoubleClick,
    'data-item-id': dataItemId,
    multiRow = false,
  } = props

  const handleClick = (event: React.MouseEvent) => onClick?.(event)
  const handleDoubleClick = (event: React.MouseEvent) => onDoubleClick?.(event)

  return (
    <ButtonBase
      sx={{
        ...(multiRow ? styles.multiRowContainer : styles.container),
        backgroundColor: selected ? Colors.selected : Colors.transparent,
        borderColor: selected ? Colors.transparent : Colors.secondary10,
      }}
      data-item-id={dataItemId}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      disableRipple
    >
      <Box sx={multiRow ? styles.multiRowImageContainer : styles.imageContainer}>
        <Box 
          component='img' 
          src={FolderImage} 
          sx={multiRow ? styles.multiRowImage : styles.image}
          draggable={false}
        />
      </Box>
      <Box sx={multiRow ? styles.multiRowNameContainer : styles.nameContainer}>
        <Typography variant='body2' sx={multiRow ? styles.multiRowName : styles.name}>
          {item?.nameFi || item?.name || item?.originalName}
        </Typography>
      </Box>
    </ButtonBase>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `0.125rem solid ${Colors.secondary10}`,
    borderRadius: '0.625rem',
    padding: '1.25rem 1.25rem 1rem 1.25rem',
    width: '13rem',
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Colors.secondary10,
    },
  },
  imageContainer: {
    width: '9rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: Colors.transparent,
    overflow: 'hidden',
    padding: 0,
    margin: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: Colors.transparent,
    // Prevent image from interfering with selection
    pointerEvents: 'none',
  },
  nameContainer: {
    width: '10.5rem',
    m: '0.875rem 0rem 0rem 0rem',
  },
  name: {
    fontSize: '1rem',
    color: Colors.text,
    fontWeight: 600,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // Prevent text selection
    userSelect: 'none',
  },
  multiRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: `0.125rem solid ${Colors.secondary10}`,
    borderRadius: '0.625rem',
    padding: '1.25rem 1.25rem 1rem 1.25rem',
    width: '13rem',
    height: '13rem',
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Colors.secondary10,
    },
  },
  multiRowImageContainer: {
    width: '9rem',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.transparent,
    overflow: 'hidden',
    padding: 0,
    margin: 0,
    flexShrink: 0,
  },
  multiRowImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    backgroundColor: Colors.transparent,
    pointerEvents: 'none',
  },
  multiRowNameContainer: {
    width: '10.5rem',
    marginTop: '0.825rem',
    height: '2.4rem',
    flexShrink: 0,
  },
  multiRowName: {
    fontSize: '1rem',
    color: Colors.text,
    fontWeight: 600,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.2em',
  },
} as const