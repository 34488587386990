import { createContext, useContext } from 'react'
import SessionStore from './SessionStore'
import UserStore from './UserStore'
import PropertyStore from './PropertyStore'
import ProjectStore from './ProjectStore'
import BuildingStore from './BuildingStore'
import ApartmentStore from './ApartmentStore'
import RoomStore from './RoomStore'
import SpaceStore from './SpaceStore'
import CostStore from './CostStore'
import NotificationStore from './NotificationStore'
import ReminderStore from './ReminderStore'
import ArchiveStore from './ArchiveStore'

class RootStore {
  sessionStore
  userStore
  propertyStore
  buildingStore
  apartmentStore
  roomStore
  spaceStore
  projectStore
  costStore
  notificationStore
  reminderStore
  archiveStore

  constructor() {
    this.sessionStore = new SessionStore(this)
    this.userStore = new UserStore(this)
    this.propertyStore = new PropertyStore(this)
    this.buildingStore = new BuildingStore(this)
    this.apartmentStore = new ApartmentStore(this)
    this.roomStore = new RoomStore(this)
    this.spaceStore = new SpaceStore(this)
    this.projectStore = new ProjectStore(this)
    this.costStore = new CostStore(this)
    this.notificationStore = new NotificationStore(this)
    this.reminderStore = new ReminderStore(this)
    this.archiveStore = new ArchiveStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext({})
export const StoreProvider = StoreContext.Provider

export const useStore = () => useContext(StoreContext)