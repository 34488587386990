import { forwardRef } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import CheckIcon from '../../Assets/Icons/check.svg'
import { Colors } from '../../Utils/theme'

const CustomCheckbox = forwardRef((props: any, ref: any) => {
  const getUncheckedContainerStyle = () => {
    if (props?.size) {
      return { ...styles.uncheckedContainer, height: props.size, width: props.size }
    }
    return styles.uncheckedContainer
  }

  const getCheckedContainerStyle = () => {
    if (props?.size) {
      return { ...styles.checkedContainer, height: props.size, width: props.size }
    }
    return styles.checkedContainer
  }

  const getIconStyle = () => {
    if (props?.iconSize) {
      return { ...styles.icon, width: props.iconSize, height: props.iconSize }
    }
    return styles.icon
  }

  const renderUnchecked = () => {
    return <Box sx={getUncheckedContainerStyle()} />
  }

  const renderChecked = () => (
    <Box sx={getCheckedContainerStyle()}>
      <Box component='img' src={CheckIcon} sx={getIconStyle()} />
    </Box>
  )

  const renderIndeterminate = () => (
    <Box sx={{
      ...getCheckedContainerStyle(),
      '&::after': {
        content: '""',
        display: 'block',
        width: '0.625rem',
        height: '0.125rem',
        backgroundColor: Colors.primaryDarkest,
        position: 'absolute'
      }
    }} />
  )

  const renderCheckbox = () => {
    if (props?.label) {
      return (
        <Box
          sx={!props?.unset ? styles.container : styles.unsetContainer}
          mb={props?.mb || '0rem'}
          ml={props?.ml || '0rem'}
          mr={props?.mr || '0rem'}
          minWidth={props?.minWidth || '0rem'}
        >
          <Checkbox
            ref={ref}
            onChange={props.onChange}
            checked={props.checked}
            indeterminate={props.indeterminate}
            checkedIcon={renderChecked()}
            icon={renderUnchecked()}
            indeterminateIcon={renderIndeterminate()}
            sx={{ padding: 0, paddingRight: '0.625rem' }}
            disabled={props?.disabled}
            disableRipple
          />
          <ButtonBase
            onClick={props.onChange}
            disabled={props.disabled}
            disableRipple
          >
            <Typography
              variant='body1'
              sx={props?.labelSx || (props?.unset ? { lineHeight: 'unset', textAlign: 'left' } : {})}
              color={props?.disabled ? Colors.heading60 : Colors.primaryDarkest}
            >
              {props.label}
            </Typography>
          </ButtonBase>
        </Box>
      )
    }
    return (
      <Checkbox
        {...props}
        ref={ref}
        checkedIcon={renderChecked()}
        icon={renderUnchecked()}
        indeterminateIcon={renderIndeterminate()}
        mb={props?.mb || '0rem'}
        disabled={!!props?.disabled}
        disableRipple
      />
    )
  }

  return renderCheckbox()
})

export default CustomCheckbox

const styles = {
  uncheckedContainer: {
    height: '1.25rem',
    width: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.3125rem'
  },
  checkedContainer: {
    height: '1.25rem',
    width: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.3125rem'
  },
  icon: {
    width: '0.875rem',
    height: '0.875rem'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  unsetContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    cursor: 'pointer'
  }
}