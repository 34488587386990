import React, { useState, useCallback, useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import debounce from 'lodash/debounce'

interface SelectionWrapperProps {
  children: React.ReactNode
  selectedItems: string[]
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  onOpen?: (itemId: string, item?: any) => void
}

interface Position {
  x: number
  y: number
}

interface SelectionRect {
  startX: number
  startY: number
  width: number
  height: number
}

interface ItemProps {
  item: {
    uuid?: string
    nameFi?: string
    [key: string]: any
  }
  selected?: boolean
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
  onDoubleClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
  'data-item-id'?: string
}

const DragThreshold = 5

export function SelectionWrapper({
  children,
  selectedItems,
  setSelectedItems,
  onOpen,
}: SelectionWrapperProps) {
  const [isDragging, setIsDragging] = useState(false)
  const [selectionRect, setSelectionRect] = useState<SelectionRect | null>(null)
  const [mouseDownPosition, setMouseDownPosition] = useState<Position | null>(null)
  const [lastSelected, setLastSelected] = useState<string | null>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const rafRef = useRef<number>()
  const elementsRef = useRef<Element[]>([])
  
  useEffect(() => {
    if (wrapperRef.current) {
      elementsRef.current = Array.from(wrapperRef.current.querySelectorAll('[data-item-id]'))
    }
  }, [children])

  const getItemsInSelection = useCallback((rect: SelectionRect): string[] => {
    if (!wrapperRef.current) return []

    return elementsRef.current
      .filter(element => {
        const itemRect = element.getBoundingClientRect()
        return (
          itemRect.left < rect.startX + rect.width &&
          itemRect.right > rect.startX &&
          itemRect.top < rect.startY + rect.height &&
          itemRect.bottom > rect.startY
        )
      })
      .map(element => element.getAttribute('data-item-id') || '')
      .filter(Boolean)
  }, [])

  const updateSelection = useCallback(
    debounce((rect: SelectionRect, isMultiSelect: boolean) => {
      const itemsInSelection = getItemsInSelection(rect)
      if (!isMultiSelect) {
        setSelectedItems(itemsInSelection)
      } else {
        setSelectedItems(prev => [...new Set([...prev, ...itemsInSelection])])
      }
    }, 16),
    [getItemsInSelection, setSelectedItems]
  )

  const handleMouseDown = useCallback((event: React.MouseEvent) => {
    // Only handle left mouse button
    if (event.button !== 0) return
    
    // Store initial position
    setMouseDownPosition({
      x: event.clientX,
      y: event.clientY,
    })
    
    // Clear any existing selection rectangle
    setSelectionRect(null)
  }, [])

  const handleMouseMove = useCallback((event: React.MouseEvent) => {
    if (!mouseDownPosition) return

    // Calculate distance moved
    const deltaX = Math.abs(event.clientX - mouseDownPosition.x)
    const deltaY = Math.abs(event.clientY - mouseDownPosition.y)
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)

    // Start dragging only after threshold
    if (!isDragging && distance > DragThreshold) {
      setIsDragging(true)
    }

    if (isDragging) {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }

      rafRef.current = requestAnimationFrame(() => {
        const newRect = {
          startX: Math.min(mouseDownPosition.x, event.clientX),
          startY: Math.min(mouseDownPosition.y, event.clientY),
          width: Math.abs(event.clientX - mouseDownPosition.x),
          height: Math.abs(event.clientY - mouseDownPosition.y),
        }

        setSelectionRect(newRect)
        if (distance > DragThreshold) {
          updateSelection(newRect, event.metaKey || event.ctrlKey)
        }
      })
    }
  }, [isDragging, mouseDownPosition, updateSelection])

  const handleMouseUp = useCallback((event: React.MouseEvent) => {
    if (isDragging) {
      setIsDragging(false)
      setSelectionRect(null)
      setMouseDownPosition(null)
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }
      return
    }

    // Check if it's a background click
    if (!event.target || !(event.target as Element).closest('[data-item-id]')) {
      setSelectedItems([])
      setLastSelected(null)
      setMouseDownPosition(null)
      return
    }

    // Handle item click
    const clickedItem = (event.target as Element).closest('[data-item-id]')
    const itemId = clickedItem?.getAttribute('data-item-id')
    
    if (itemId) {
      if (event.metaKey || event.ctrlKey) {
        setSelectedItems(prev => 
          prev.includes(itemId)
            ? prev.filter(id => id !== itemId)
            : [...prev, itemId]
        )
      } else if (event.shiftKey && lastSelected) {
        const itemIds = elementsRef.current.map(el => el.getAttribute('data-item-id') || '')
        const startIdx = itemIds.indexOf(lastSelected)
        const endIdx = itemIds.indexOf(itemId)
        const [start, end] = [Math.min(startIdx, endIdx), Math.max(startIdx, endIdx)]
        const rangeSelection = itemIds.slice(start, end + 1).filter(Boolean)
        setSelectedItems(prev => [...new Set([...prev, ...rangeSelection])])
      } else {
        setSelectedItems([itemId])
      }
      setLastSelected(itemId)
    }
    
    setMouseDownPosition(null)
  }, [isDragging, lastSelected, setSelectedItems])

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isDragging || mouseDownPosition) {
        setIsDragging(false)
        setSelectionRect(null)
        setMouseDownPosition(null)
        if (rafRef.current) {
          cancelAnimationFrame(rafRef.current)
        }
      }
    }
    
    window.addEventListener('mouseup', handleGlobalMouseUp)
    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp)
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }
    }
  }, [isDragging, mouseDownPosition])

  // Clone children with selection props
  const output = React.Children.map(children, child => {
    if (React.isValidElement<ItemProps>(child)) {
      const itemId = child.props.item?.uuid || child.props.item?.path || ''
      return React.cloneElement(child, {
        ...child.props,
        'data-item-id': itemId,
        selected: Boolean(itemId && selectedItems.includes(itemId)),
        onDoubleClick: onOpen ? () => onOpen(itemId, child.props.item) : undefined,
      })
    }
    return child
  })

  return (
    <Box
      ref={wrapperRef}
      sx={styles.container}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {output}
      {isDragging && selectionRect && (
        <Box
          sx={{
            ...styles.selection,
            left: selectionRect.startX,
            top: selectionRect.startY,
            width: selectionRect.width,
            height: selectionRect.height,
          }}
        />
      )}
    </Box>
  )
}

const styles = {
  container: {
    position: 'relative',
    userSelect: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    width: '100%',
    height: 'calc(100vh - 14.25rem)',
    gap: '1.5rem',
    // pt: '1.5rem',
    isolation: 'isolate',
    overflowY: 'auto',
    overflowX: 'hidden',
    pt: '1.25rem',
    pb: '20rem'
  },
  selection: {
    position: 'fixed',
    border: '2px solid rgba(33, 150, 243, 0.8)',
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
    pointerEvents: 'none',
    zIndex: 9999,
  }
} as const