import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { DateTime } from 'luxon'

export default class ArchiveStore {
  rootStore

  items = []
  item = null
  foundItems = []
  pendingItems = []
  path = []
  logs = []
  downloading = false
  uploading = false
  deleting = false
  progress = 0
  actionLoading = false
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setItems = (items: any) => { this.items = items }
  setItem = (item: any) => { this.item = item }
  setFoundItems = (foundItems: any) => { this.foundItems = foundItems }
  setPendingItems = (pendingItems: any) => { this.pendingItems = pendingItems }
  setPath = (path: any) => { this.path = path }
  setLogs = (logs: any) => { this.logs = logs }
  setDownloading = (downloading: boolean) => { this.downloading = downloading }
  setUploading = (uploading: boolean) => { this.uploading = uploading }
  setDeleting = (deleting: boolean) => { this.deleting = deleting }
  setProgress = (progress: number) => { this.progress = progress }
  setActionLoading = (actionLoading: boolean) => { this.actionLoading = actionLoading }
  setLoading = (loading: boolean) => { this.loading = loading }

  clearUploadItems = () => {
    this.setPendingItems([])
    this.setFoundItems([])
  }
  clearProgress = () => {
    this.setUploading(false)
    this.setDownloading(false)
    this.setDeleting(false)
    this.setProgress(0)
    this.setPendingItems([])
    this.setFoundItems([])
  }

  reset() {
    this.items = []
    this.item = null
    this.path = []
    this.logs = []
    this.foundItems = []
    this.pendingItems = []
    this.downloading = false
    this.uploading = false
    this.progress = 0
    this.actionLoading = false
    this.loading = false
  }

  async getArchiveProperties() {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchiveProperties()
      if (response?.ok) {
        const folders = response.data?.items || []
        this.setItems(folders)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getArchivePropertyFolders(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchivePropertyFolders(pUuid)
      if (response?.ok) {
        const folders = response.data?.items || []
        this.setItems(folders)
        const path = response.data?.path || []
        this.setPath(path)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getArchivePropertyFolder(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchivePropertyFolder(pUuid)
      if (response?.ok) {
        const folder = response.data?.items || []
        this.setItems(folder)
        const path = response.data?.path || []
        this.setPath(path)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getArchiveBuildings(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchiveBuildings(pUuid)
      if (response?.ok) {
        const folders = response.data?.items || []
        this.setItems(folders)
        const path = response.data?.path || []
        this.setPath(path)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getArchiveBuildingFolder(pUuid: string, bUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchiveBuildingFolder(pUuid, bUuid)
      if (response?.ok) {
        const files = response.data?.items || []
        this.setItems(files)
        const path = response.data?.path || []
        this.setPath(path)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getArchiveApartments(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchiveApartments(pUuid)
      if (response?.ok) {
        const folders = response.data?.items || []
        this.setItems(folders)
        const path = response.data?.path || []
        this.setPath(path)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getArchiveApartmentFolder(pUuid: string, aUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchiveApartmentFolder(pUuid, aUuid)
      if (response?.ok) {
        const files = response.data?.items || []
        this.setItems(files)
        const path = response.data?.path || []
        this.setPath(path)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getArchiveProjects(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchiveProjects(pUuid)
      if (response?.ok) {
        const folders = response.data?.items || []
        this.setItems(folders)
        const path = response.data?.path || []
        this.setPath(path)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getArchiveProjectFolder(pUuid: string, projUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getArchiveProjectFolder(pUuid, projUuid)
      if (response?.ok) {
        const files = response.data?.items || []
        this.setItems(files)
        const path = response.data?.path || []
        this.setPath(path)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async downloadPropertyFolders(uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        const zip = new JSZip()
        const maxItems = items?.reduce((acc: number, item: any) => acc + item?.files?.length, 0)
        let index = 1
        for (const item of items) {
          if (item?.files?.length) {
            // Create a sub-folder named with the property name
            const folder: any = zip.folder(item?.nameFi || item?.uuid)
            // Fetch and add files to the newly created sub-folder
            for (const file of item.files) {
              const response: any = await Api.downloadPropertyFile(item?.uuid, file?.uuid)
              if (response?.ok) {
                this.setProgress((index / maxItems) * 100)
                // Create a blob from the response
                const blob = new Blob([response.data], { type: file?.mimeType })
                // Add the file to the sub-folder
                folder.file(file?.name || file?.originalName, blob)
              }
              index++
            }
          }
        }
        // Generate the ZIP
        const content = await zip.generateAsync({ type: 'blob' })
        // And finally save it
        saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async downloadPropertySubFolders(pUuid: string, uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        const zip = new JSZip()
        const maxItems = items?.reduce((acc: number, item: any) => acc + item?.files?.length, 0)
        let index = 1
        for (const item of items) {
          if (item?.files?.length) {
            // Create a sub-folder named with the sub-folder name
            const folder: any = zip.folder(item?.nameFi || item?.uuid)
            // Fetch and add files to the newly created sub-folder
            for (const file of item.files) {
              const response: any = await Api.downloadPropertyFile(pUuid, file?.uuid)
              if (response?.ok) {
                this.setProgress((index / maxItems) * 100)
                // Create a blob from the response
                const blob = new Blob([response.data], { type: file?.mimeType })
                // Add the file to the sub-folder
                folder.file(file?.name || file?.originalName, blob)
              }
              index++
            }
          }
        }
        // Generate the ZIP
        const content = await zip.generateAsync({ type: 'blob' })
        // And finally save it
        saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async downloadBuildingFolders(pUuid: string, uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        const zip = new JSZip()
        const maxItems = items?.reduce((acc: number, item: any) => acc + item?.files?.length, 0)
        let index = 1
        for (const item of items) {
          if (item?.files?.length) {
            // Create a sub-folder named with the building name
            const folder: any = zip.folder(item?.nameFi || item?.uuid)
            // Fetch and add files to the newly created sub-folder
            for (const file of item.files) {
              const response: any = await Api.downloadBuildingFile(pUuid, item?.uuid, file?.uuid)
              if (response?.ok) {
                this.setProgress((index / maxItems) * 100)
                // Create a blob from the response
                const blob = new Blob([response.data], { type: file?.mimeType })
                // Add the file to the sub-folder
                folder.file(file?.name || file?.originalName, blob)
              }
              index++
            }
          }
        }
        // Generate the ZIP
        const content = await zip.generateAsync({ type: 'blob' })
        // And finally save it
        saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async downloadApartmentFolders(pUuid: string, uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        const zip = new JSZip()
        const maxItems = items?.reduce((acc: number, item: any) => acc + item?.files?.length, 0)
        let index = 1
        for (const item of items) {
          if (item?.files?.length) {
            // Create a sub-folder named with the apartment name
            const folder: any = zip.folder(item?.nameFi || item?.uuid)
            // Fetch and add files to the newly created sub-folder
            for (const file of item.files) {
              const response: any = await Api.downloadApartmentFile(pUuid, item?.uuid, file?.uuid)
              if (response?.ok) {
                this.setProgress((index / maxItems) * 100)
                // Create a blob from the response
                const blob = new Blob([response.data], { type: file?.mimeType })
                // Add the file to the sub-folder
                folder.file(file?.name || file?.originalName, blob)
              }
              index++
            }
          }
        }
        // Generate the ZIP
        const content = await zip.generateAsync({ type: 'blob' })
        // And finally save it
        saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async downloadProjectFolders(pUuid: string, uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        const zip = new JSZip()
        const maxItems = items?.reduce((acc: number, item: any) => acc + item?.files?.length, 0)
        let index = 1
        for (const item of items) {
          if (item?.files?.length) {
            // Create a sub-folder named with the project name
            const folder: any = zip.folder(item?.nameFi || item?.uuid)
            // Fetch and add files to the newly created sub-folder
            for (const file of item.files) {
              const response: any = await Api.downloadProjectFile(pUuid, item?.uuid, file?.uuid)
              if (response?.ok) {
                this.setProgress((index / maxItems) * 100)
                // Create a blob from the response
                const blob = new Blob([response.data], { type: file?.mimeType })
                // Add the file to the sub-folder
                folder.file(file?.name || file?.originalName, blob)
              }
              index++
            }
          }
        }
        // Generate the ZIP
        const content = await zip.generateAsync({ type: 'blob' })
        // And finally save it
        saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async downloadPropertyFiles(pUuid: string, uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        if (items?.length === 1) {
          const response: any = await Api.downloadPropertyFile(pUuid, items[0]?.uuid)
          if (response?.ok) {
            this.setProgress(100)
            const blob = new Blob([response.data], { type: items[0]?.mimeType })
            saveAs(blob, items[0]?.name || items[0]?.originalName)
          }
        } else {
          const zip = new JSZip()
          let index = 1
          for (const item of items) {
            const response: any = await Api.downloadPropertyFile(pUuid, item?.uuid)
            if (response?.ok) {
              this.setProgress((index / items?.length) * 100)
              const blob = new Blob([response.data], { type: item?.mimeType })
              zip.file(item?.name || item?.originalName, blob)
              index++
            }
          }
          const content = await zip.generateAsync({ type: 'blob' })
          saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
        }
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async downloadBuildingFiles(pUuid: string, bUuid: string, uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        if (items?.length === 1) {
          const response: any = await Api.downloadBuildingFile(pUuid, bUuid, items[0]?.uuid)
          if (response?.ok) {
            this.setProgress(100)
            const blob = new Blob([response.data], { type: items[0]?.mimeType })
            saveAs(blob, items[0]?.name || items[0]?.originalName)
          }
        } else {
          const zip = new JSZip()
          let index = 1
          for (const item of items) {
            const response: any = await Api.downloadBuildingFile(pUuid, bUuid, item?.uuid)
            if (response?.ok) {
              this.setProgress((index / items?.length) * 100)
              const blob = new Blob([response.data], { type: item?.mimeType })
              zip.file(item?.name || item?.originalName, blob)
              index++
            }
          }
          const content = await zip.generateAsync({ type: 'blob' })
          saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
        }
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async downloadApartmentFiles(pUuid: string, aUuid: string, uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        if (items?.length === 1) {
          const response: any = await Api.downloadApartmentFile(pUuid, aUuid, items[0]?.uuid)
          if (response?.ok) {
            this.setProgress(100)
            const blob = new Blob([response.data], { type: items[0]?.mimeType })
            saveAs(blob, items[0]?.name || items[0]?.originalName)
          }
        } else {
          const zip = new JSZip()
          let index = 1
          for (const item of items) {
            const response: any = await Api.downloadApartmentFile(pUuid, aUuid, item?.uuid)
            if (response?.ok) {
              this.setProgress((index / items?.length) * 100)
              const blob = new Blob([response.data], { type: item?.mimeType })
              zip.file(item?.name || item?.originalName, blob)
              index++
            }
          }
          const content = await zip.generateAsync({ type: 'blob' })
          saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
        }
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async downloadProjectFiles(pUuid: string, projUuid: string, uuids: string[]) {
    this.setProgress(0)
    this.setDownloading(true)
    try {
      if (uuids?.length) {
        const items: any = this.items?.filter((item: any) => uuids.includes(item.uuid))
        if (items?.length === 1) {
          const response: any = await Api.downloadProjectFile(pUuid, projUuid, items[0]?.uuid)
          if (response?.ok) {
            this.setProgress(100)
            const blob = new Blob([response.data], { type: items[0]?.mimeType })
            saveAs(blob, items[0]?.name || items[0]?.originalName)
          }
        } else {
          const zip = new JSZip()
          let index = 1
          for (const item of items) {
            const response: any = await Api.downloadProjectFile(pUuid, projUuid, item?.uuid)
            if (response?.ok) {
              this.setProgress((index / items?.length) * 100)
              const blob = new Blob([response.data], { type: item?.mimeType })
              zip.file(item?.name || item?.originalName, blob)
              index++
            }
          }
          const content = await zip.generateAsync({ type: 'blob' })
          saveAs(content, `${DateTime.now().toFormat('dd_MM_yyyy_HH_mm')}.zip`)
        }
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async checkIfPropertyFilesExist(pUuid: string, files: any, callback?: any) {
    this.setActionLoading(true)
    try {
      this.setPendingItems(files)
      const names = files?.map((file: any) => file?.name)
      const response: any = await Api.checkIfPropertyFilesExist(pUuid, names)
      if (response?.ok) {
        const items = response.data?.items || []
        if (items?.length) {
          this.setFoundItems(items)
          if (callback) {
            callback()
          }
        } else {
          this.uploadPropertyFiles(pUuid)
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }

  async uploadPropertyFiles(pUuid: string, shouldReplace?: boolean) {
    this.setProgress(0)
    this.setUploading(true)
    try {
      const files: any = this.pendingItems
      const okResponses = []
      const errorResponses = []
      let index = 1
      for (const file of files) {
        const response: any = await Api.uploadPropertyFile(
          pUuid,
          file,
          shouldReplace ?
            this.foundItems?.some((item: any) => {
              return item?.name === file?.name ||
                (!item?.name && item?.originalName === file?.name)
            }) :
            false
        )
        if (response?.ok) {
          this.setProgress((index / files?.length) * 100)
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
        index++
      }
      if (okResponses?.length) {
        this.getArchivePropertyFolder(pUuid)
      } else {
        this.clearProgress()
        showMessage(i18n.t('file_upload_failed'), 'error')
        return
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async checkIfBuildingFilesExist(pUuid: string, bUuid: string, files: any, callback?: any) {
    this.setActionLoading(true)
    try {
      this.setPendingItems(files)
      const names = files?.map((file: any) => file?.name)
      const response: any = await Api.checkIfBuildingFilesExist(pUuid, bUuid, names)
      if (response?.ok) {
        const items = response.data?.items || []
        if (items?.length) {
          this.setFoundItems(items)
          if (callback) {
            callback()
          }
        } else {
          this.uploadBuildingFiles(pUuid, bUuid)
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }

  async uploadBuildingFiles(pUuid: string, bUuid: string, shouldReplace?: boolean) {
    this.setProgress(0)
    this.setUploading(true)
    try {
      const files: any = this.pendingItems
      const okResponses = []
      const errorResponses = []
      let index = 1
      for (const file of files) {
        const response: any = await Api.uploadBuildingFile(
          pUuid,
          bUuid,
          file,
          shouldReplace ?
            this.foundItems?.some((item: any) => {
              return item?.name === file?.name ||
                (!item?.name && item?.originalName === file?.name)
            }) :
            false
        )
        if (response?.ok) {
          this.setProgress((index / files?.length) * 100)
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
        index++
      }
      if (okResponses?.length) {
        this.getArchiveBuildingFolder(pUuid, bUuid)
      } else {
        this.clearProgress()
        showMessage(i18n.t('file_upload_failed'), 'error')
        return
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async checkIfApartmentFilesExist(pUuid: string, aUuid: string, files: any, callback?: any) {
    this.setActionLoading(true)
    try {
      this.setPendingItems(files)
      const names = files?.map((file: any) => file?.name)
      const response: any = await Api.checkIfApartmentFilesExist(pUuid, aUuid, names)
      if (response?.ok) {
        const items = response.data?.items || []
        if (items?.length) {
          this.setFoundItems(items)
          if (callback) {
            callback()
          }
        } else {
          this.uploadApartmentFiles(pUuid, aUuid)
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }

  async uploadApartmentFiles(pUuid: string, aUuid: string, shouldReplace?: boolean) {
    this.setProgress(0)
    this.setUploading(true)
    try {
      const files: any = this.pendingItems
      const okResponses = []
      const errorResponses = []
      let index = 1
      for (const file of files) {
        const response: any = await Api.uploadApartmentFile(
          pUuid,
          aUuid,
          file,
          shouldReplace ?
            this.foundItems?.some((item: any) => {
              return item?.name === file?.name ||
                (!item?.name && item?.originalName === file?.name)
            }) :
            false
        )
        if (response?.ok) {
          this.setProgress((index / files?.length) * 100)
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
        index++
      }
      if (okResponses?.length) {
        this.getArchiveApartmentFolder(pUuid, aUuid)
      } else {
        this.clearProgress()
        showMessage(i18n.t('file_upload_failed'), 'error')
        return
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async checkIfProjectFilesExist(pUuid: string, projUuid: string, files: any, callback?: any) {
    this.setActionLoading(true)
    try {
      this.setPendingItems(files)
      const names = files?.map((file: any) => file?.name)
      const response: any = await Api.checkIfProjectFilesExist(pUuid, projUuid, names)
      if (response?.ok) {
        const items = response.data?.items || []
        if (items?.length) {
          this.setFoundItems(items)
          if (callback) {
            callback()
          }
        } else {
          this.uploadProjectFiles(pUuid, projUuid)
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }

  async uploadProjectFiles(pUuid: string, projUuid: string, shouldReplace?: boolean) {
    this.setProgress(0)
    this.setUploading(true)
    try {
      const files: any = this.pendingItems
      const okResponses = []
      const errorResponses = []
      let index = 1
      for (const file of files) {
        const response: any = await Api.uploadProjectFile(
          pUuid,
          projUuid,
          file,
          shouldReplace ?
            this.foundItems?.some((item: any) => {
              return item?.name === file?.name ||
                (!item?.name && item?.originalName === file?.name)
            }) :
            false
        )
        if (response?.ok) {
          this.setProgress((index / files?.length) * 100)
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
        index++
      }
      if (okResponses?.length) {
        this.getArchiveProjectFolder(pUuid, projUuid)
      } else {
        this.clearProgress()
        showMessage(i18n.t('file_upload_failed'), 'error')
        return
      }
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async updatePropertyFile(pUuid: string, fUuid: string, payload: any) {
    this.setActionLoading(true)
    try {
      const response: any = await Api.updatePropertyFile(pUuid, fUuid, payload)
      if (response?.ok) {
        showMessage(i18n.t('file_updated'))
        await this.getArchivePropertyFolder(pUuid)
        const item = this.items.find((item: any) => item?.uuid === fUuid)
        this.setItem(item)
      } else {
        showMessage(i18n.t('file_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }

  async updateBuildingFile(pUuid: string, bUuid: string, fUuid: string, payload: any) {
    this.setActionLoading(true)
    try {
      const response: any = await Api.updateBuildingFile(pUuid, bUuid, fUuid, payload)
      if (response?.ok) {
        showMessage(i18n.t('file_updated'))
        await this.getArchiveBuildingFolder(pUuid, bUuid)
        const item = this.items.find((item: any) => item?.uuid === fUuid)
        this.setItem(item)
      } else {
        showMessage(i18n.t('file_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }

  async updateApartmentFile(pUuid: string, aUuid: string, fUuid: string, payload: any) {
    this.setActionLoading(true)
    try {
      const response: any = await Api.updateApartmentFile(pUuid, aUuid, fUuid, payload)
      if (response?.ok) {
        showMessage(i18n.t('file_updated'))
        await this.getArchiveApartmentFolder(pUuid, aUuid)
        const item = this.items.find((item: any) => item?.uuid === fUuid)
        this.setItem(item)
      } else {
        showMessage(i18n.t('file_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }

  async updateProjectFile(pUuid: string, projUuid: string, fUuid: string, payload: any) {
    this.setActionLoading(true)
    try {
      const response: any = await Api.updateProjectFile(pUuid, projUuid, fUuid, payload)
      if (response?.ok) {
        showMessage(i18n.t('file_updated'))
        await this.getArchiveProjectFolder(pUuid, projUuid)
        const item = this.items.find((item: any) => item?.uuid === fUuid)
        this.setItem(item)
      } else {
        showMessage(i18n.t('file_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }
  
  async archivePropertyFiles(pUuid: string, uuids: string[], callback?: any) {
    this.setProgress(0)
    this.setDeleting(true)
    try {
      let index = 1
      for (const uuid of uuids) {
        const response: any = await Api.archivePropertyFile(pUuid, uuid)
        if (response?.ok) {
          this.setProgress((index / uuids?.length) * 100)
        } else {
          showMessage(i18n.t('file_archive_failed'), 'error')
        }
        index++
      }
      if (callback) {
        callback()
      }
      this.getArchivePropertyFolder(pUuid)
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async archiveBuildingFiles(pUuid: string, bUuid: string, uuids: string[], callback?: any) {
    this.setProgress(0)
    this.setDeleting(true)
    try {
      let index = 1
      for (const uuid of uuids) {
        const response: any = await Api.archiveBuildingFile(pUuid, bUuid, uuid)
        if (response?.ok) {
          this.setProgress((index / uuids?.length) * 100)
        } else {
          showMessage(i18n.t('file_archive_failed'), 'error')
        }
        index++
      }
      if (callback) {
        callback()
      }
      this.getArchiveBuildingFolder(pUuid, bUuid)
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async archiveApartmentFiles(pUuid: string, aUuid: string, uuids: string[], callback?: any) {
    this.setProgress(0)
    this.setDeleting(true)
    try {
      let index = 1
      for (const uuid of uuids) {
        const response: any = await Api.archiveApartmentFile(pUuid, aUuid, uuid)
        if (response?.ok) {
          this.setProgress((index / uuids?.length) * 100)
        } else {
          showMessage(i18n.t('file_archive_failed'), 'error')
        }
        index++
      }
      if (callback) {
        callback()
      }
      this.getArchiveApartmentFolder(pUuid, aUuid)
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async archiveProjectFiles(pUuid: string, projUuid: string, uuids: string[], callback?: any) {
    this.setProgress(0)
    this.setDeleting(true)
    try {
      let index = 1
      for (const uuid of uuids) {
        const response: any = await Api.archiveProjectFile(pUuid, projUuid, uuid)
        if (response?.ok) {
          this.setProgress((index / uuids?.length) * 100)
        } else {
          showMessage(i18n.t('file_archive_failed'), 'error')
        }
        index++
      }
      if (callback) {
        callback()
      }
      this.getArchiveProjectFolder(pUuid, projUuid)
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => this.clearProgress(), 3000)
  }

  async getFileInfo(pUuid: string, fUuid: string) {
    this.setActionLoading(true)
    try {
      const response: any = await Api.getArchiveFileInfo(pUuid, fUuid)
      if (response?.ok) {
        const item = response.data
        this.setItem(item)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }

  async getFileLogs(pUuid: string, fUuid: string) {
    this.setActionLoading(true)
    try {
      const response: any = await Api.getArchiveFileLogs(pUuid, fUuid)
      if (response?.ok) {
        const logs = response.data?.items || []
        this.setLogs(logs)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setActionLoading(false)
  }
}