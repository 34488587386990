import i18n from '../Localization'

export function toCents(amount: number) {
  if (!amount) return 0
  return amount * 100
}
export function fromCents(amount: number) {
  if (!amount) return 0
  return amount / 100
}

export function formatCurrency(
  amount: number | null,
  currency: string,
  precision = 2,
) {
  if (amount === null) return ''
  return `${amount.toFixed(precision)} ${currency}`
}

export function formatFileSize(size: number) {
  if (!size) return '-'
  if (size === 0) {
    return '0 B'
  }
  if (size < 1024) {
    return `${size} B`
  } else if (size < 1024 * 1024) {
    return `${Math.round((size / 1024))} KB`
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(1)?.replace('.', ',')} MB`
  } else {
    return `${(size / 1024 / 1024 / 1024).toFixed(2)?.replace('.', ',')} GB`
  }
}

const MimeTypeMappings: any = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'text/plain': 'txt',
}

export function formatFileType(mimeType: string) {
  if (!mimeType) return ''
  
  const type = MimeTypeMappings?.[mimeType] || ''
  return type.toUpperCase()
}

enum EventAction {
  Read = 'read',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Archive = 'archive',
}

const { Create, Update, Archive } = EventAction

enum EventTarget {
  Account = 'account',
  User = 'user',
  Property = 'property',
  Building = 'building',
  Apartment = 'apartment',
  Room = 'room',
  Space = 'space',
  Project = 'project',
  ProjectComment = 'project_comment',
  File = 'file',
}

const {
  // Account,
  User,
  Property,
  Building,
  Apartment,
  Room,
  Space,
  Project,
  ProjectComment,
  File,
} = EventTarget

enum ActionContentType {
  Text = 'text',
  Highlight = 'highlight',
}

const { Text, Highlight } = ActionContentType

export function getNotificationActionDetails(notification: any) {
  if (!notification) return ''

  const action = notification.action
  const target = notification.target

  if (action === Create) {
    return i18n.t(`created_new_${target}`)
  } else if (action === Update) {
    return i18n.t(`updated_${target}_information`)
  } else if (action === Archive) {
    return i18n.t(`archived_${target}`)
  }

  return ''
}

export function getNotificationActionContent(notification: any) {
  if (!notification) return []

  const action = notification.action
  const target = notification.target

  if (target === Property) {
    const name = notification?.property?.nameFi || ''
    if (action === Create) {
      const city = notification?.property?.city || ''
      return [
        { type: Highlight, text: name },
        city ? { type: Text, text: i18n.t('to_city') } : null,
        city ? { type: Highlight, text: notification?.property?.city || '' } : null,
      ].filter(Boolean)
    } else if (action === Update) {
      return [
        { type: Highlight, text: name },
      ]
    } else if (action === Archive) {
      return [
        { type: Highlight, text: name },
      ]
    }
  } else if (target === Building) {
    const name = notification?.building?.nameFi || ''
    if (action === Create) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('to_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    } else if (action === Update) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    } else if (action === Archive) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    }
  } else if (target === Apartment) {
    const name = notification?.apartment?.nameFi || ''
    if (action === Create) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('to_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    } else if (action === Update) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    } else if (action === Archive) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    }
  } else if (target === Room) {
    const name = notification?.room?.nameFi || ''
    if (action === Create) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('to_apartment') },
        { type: Highlight, text: notification?.apartment?.nameFi || '' },
      ]
    } else if (action === Update) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_propertys') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
        { type: Text, text: i18n.t('from_apartment') },
        { type: Highlight, text: notification?.apartment?.nameFi || '' },
      ]
    } else if (action === Archive) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_propertys') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
        { type: Text, text: i18n.t('from_apartment') },
        { type: Highlight, text: notification?.apartment?.nameFi || '' },
      ]
    }
  } else if (target === Space) {
    const name = notification?.space?.nameFi || ''
    if (action === Create) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('to_building') },
        { type: Highlight, text: notification?.building?.nameFi || '' },
      ]
    } else if (action === Update) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_propertys') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
        { type: Text, text: i18n.t('from_building') },
        { type: Highlight, text: notification?.building?.nameFi || '' },
      ]
    } else if (action === Archive) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_propertys') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
        { type: Text, text: i18n.t('from_building') },
        { type: Highlight, text: notification?.building?.nameFi || '' },
      ]
    }
  } else if (target === User) {
    const user = notification?.targetUser
    const name = `${user?.firstName || ''} ${user?.lastName || ''}`
    if (action === Create) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('with_role') },
        { type: Highlight, text: i18n.t(user?.role) },
        // { type: Text, text: i18n.t('to_property') },
        // { type: Highlight, text: notification?.property?.nameFi || '' }
      ]
    } else if (action === Update) {
      return [
        { type: Highlight, text: name },
      ]
    } else if (action === Archive) {
      return [
        { type: Highlight, text: name },
      ]
    }
  } else if (target === Project) {
    const name = notification?.project?.nameFi || ''
    if (action === Create) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('to_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    } else if (action === Update) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    } else if (action === Archive) {
      return [
        { type: Highlight, text: name },
        { type: Text, text: i18n.t('from_property') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
      ]
    }
  } else if (target === ProjectComment) {
    const comment = notification?.projectComment
    if (action === Create) {
      return [
        { type: Highlight, text: `"${comment?.text || ''}"` },
        { type: Text, text: i18n.t('to_propertys') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
        { type: Text, text: i18n.t('to_project') },
        { type: Highlight, text: notification?.project?.nameFi || '' },
      ]
    } else if (action === Update) {
      return [
        { type: Highlight, text: `"${comment?.text || ''}"` },
        { type: Text, text: i18n.t('to_propertys') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
        { type: Text, text: i18n.t('to_project') },
        { type: Highlight, text: notification?.project?.nameFi || '' },
      ]
    } else if (action === Archive) {
      return [
        { type: Highlight, text: `"${comment?.text || ''}"` },
        { type: Text, text: i18n.t('to_propertys') },
        { type: Highlight, text: notification?.property?.nameFi || '' },
        { type: Text, text: i18n.t('to_project') },
        { type: Highlight, text: notification?.project?.nameFi || '' },
      ]
    }
  } else if (target === File) {
    // console.log('notification', notification)
    // const name = notification?.file?.name || notification?.file?.originalName || ''
  }

  return []
}

export function getArchiveLogActionDetails(log: any) {
  if (!log) return ''

  const action = log.action
  const target = File

  if (action === Create) {
    return i18n.t(`created_${target}`)
  } else if (action === Update) {
    return i18n.t(`updated_${target}_information`)
  } else if (action === Archive) {
    return i18n.t(`archived_${target}`)
  }

  return ''
}

export function getArchiveLogActionContent(log: any) {
  if (!log) return []

  if (log?.payload && log.payload?.oldName && log.payload?.name) {
    return [
      { type: Highlight, text: `"${log.payload?.oldName || ''}"` },
      { type: Text, text: '->' },
      { type: Highlight, text: `"${log.payload?.name || ''}"` },
    ]
  }

  return []
}