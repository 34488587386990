import React from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { AppLayout } from '../../Layouts'
import Users from './Users/Users'
import CreateUser from './Users/CreateUser'
import User from './Users/User'
import Profile from './Profile/Profile'
import Support from './Support/Support'
import Properties from './Properties/Properties'
import Property from './Properties/Property'
import Building from './Buildings/Building'
import Space from './Spaces/Space'
import Apartment from './Apartments/Apartment'
import Room from './Rooms/Room'
import Projects from './Projects/Projects'
import CreateProject from './Projects/CreateProject'
import EditProject from './Projects/EditProject'
import Costs from './Costs/Costs'
import CostSettings from './Costs/CostSettings'
import Archive from './Archive/Archive'

type ProtectedRouteProps = {
  isAllowed: boolean
  redirectPath?: string
}

type AuthenticatedRouterProps = {
  isAdmin: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  redirectPath = '/properties',
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default function Authenticated({ isAdmin }: AuthenticatedRouterProps) {
  return (
    <AppLayout>
      <Routes>
        {/* <Route path='/dash' element={<Dashboard />} /> */}
        <Route element={<ProtectedRoute isAllowed={isAdmin} />}>
          <Route path='/users' element={<Users />} />
          <Route path='/users/create' element={<CreateUser />} />
          <Route path='/users/:uuid' element={<User />} />
        </Route>
        <Route path='/profile' element={<Profile />} />
        <Route path='/support' element={<Support />} />
        <Route path='/properties' element={<Properties />} />
        <Route path='/properties/:uuid' element={<Property />} />
        <Route path='/properties/:pUuid/buildings/:bUuid' element={<Building />} />
        <Route path='/properties/:pUuid/buildings/:bUuid/spaces/:sUuid' element={<Space />} />
        <Route path='/properties/:pUuid/apartments/:aUuid' element={<Apartment />} />
        <Route path='/properties/:pUuid/apartments/:aUuid/rooms/:rUuid' element={<Room />} />
        <Route path='/properties/:uuid/projects' element={<Projects />} />
        <Route path='/properties/:uuid/projects/create' element={<CreateProject />} />
        <Route path='/properties/:pUuid/projects/:projUuid/edit' element={<EditProject />} />
        <Route path='/properties/:uuid/costs' element={<Costs />} />
        <Route path='/properties/:pUuid/cost-settings' element={<CostSettings />} />
        <Route path='/archive' element={<Archive />} />
        <Route path='/archive/properties/:pUuid' element={<Archive />} />
        <Route path='/archive/properties/:pUuid/:type' element={<Archive />} />
        <Route path='/archive/properties/:pUuid/:type/:uuid' element={<Archive />} />
        <Route path='*' element={<Navigate to ='/properties' />}/>
      </Routes>
    </AppLayout>
  )
}
