import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import upperFirst from 'lodash/upperFirst'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import DefaultProfilePhoto from '../../Assets/Images/user.png'
import { getArchiveLogActionDetails, getArchiveLogActionContent } from '../../Utils/transformers'
import { Colors, Fonts } from '../../Utils/theme'

export default function LogItem(props: any) {
  const { t } = useTranslation()

  const { log } = props

  const UserDetails: string = useMemo(() => {
    if (log?.creatorUser) {
      return `${log.creatorUser.firstName} ${log.creatorUser.lastName}`?.trim()
    }
    return ''
  }, [log])

  const ActionDetails = useMemo(() => {
    if (log) {
      return getArchiveLogActionDetails(log)
    }
    return ''
  }, [log])

  const CreatedAt: string = useMemo(() => {
    if (log?.createdAt) {
      const weekday = upperFirst(DateTime.fromISO(log?.createdAt).setLocale('fi').toFormat('cccc'))
      const day = DateTime.fromISO(log.createdAt).toFormat('dd.MM.yyyy')
      const time = DateTime.fromISO(log.createdAt).toFormat('HH.mm')
      return `${weekday} ${day} ${t('at')} ${time}`
    }
    return ''
  }, [log])

  const logContent = useMemo(() => {
    if (log) {
      const contentParts = getArchiveLogActionContent(log)
      if (!contentParts?.length) {
        return null
      }
      return (
        <Box sx={styles.log}>
          <Typography variant='body1' sx={styles.text}>
            {contentParts.map((part: any, index: number) => (
              <Box component='span' key={index}>
                {
                  part?.type === 'highlight' ? (
                    <Box component='span' sx={styles.highlight}>{part.text} </Box>
                  ) : `${part.text} `
                }
              </Box>
            ))}
          </Typography>
        </Box>
      )
    }
    return null
  }, [log])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.row}>
        <Avatar
          sx={styles.avatar}
          src={log?.creatorUser?.profilePhoto || DefaultProfilePhoto}
          alt='photo'
        />
        <Box sx={styles.column}>
          <Typography variant='h5' sx={styles.title}>
            {UserDetails} {ActionDetails}
          </Typography>
          <Typography variant='body1' sx={styles.createdAt}>{CreatedAt}</Typography>
          {logContent}
        </Box>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.secondary}`,
    p: '1rem',
    mb: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  avatar: {
    width: '2.125rem',
    height: '2.125rem'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 'calc(100% - 2.125rem)',
    pt: '0.0625rem',
    ml: '0.75rem'
  },
  title: {
    fontFamily: Fonts.heading,
    color: Colors.heading,
    fontWeight: 700,
    fontSize: '0.875rem'
  },
  createdAt: {
    color: Colors.heading,
    fontWeight: 400,
    fontSize: '0.75rem'
  },
  log: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: Colors.primary,
    borderRadius: '0.3125rem',
    p: '1rem',
    mt: '1rem'
  },
  text: {
    color: Colors.white,
    fontSize: '1rem'
  },
  highlight: {
    fontWeight: 700
  },
  backdrop: {
    backgroundColor: Colors.backdrop,
    zIndex: 1000
  }
} as const