import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import PdfIcon from '../../Assets/Images/pdf-2.png'
import DocIcon from '../../Assets/Images/doc-2.png'
import ExcelIcon from '../../Assets/Images/xls-2.png'
import PowerpointIcon from '../../Assets/Images/ppt-2.png'
import TextIcon from '../../Assets/Images/txt-2.png'
import { FileTypes } from '../../Utils/constants'
import { Colors } from '../../Utils/theme'
import { DateTime } from 'luxon'

interface ArchiveGridFileProps {
  item: {
    uuid?: string
    nameFi?: string
    name?: string
    originalName?: string
    [key: string]: any
  }
  selected?: boolean
  onClick?: (event: React.MouseEvent) => void
  onDoubleClick?: (event: React.MouseEvent) => void
  'data-item-id'?: string
}

export default function ArchiveGriditem(props: ArchiveGridFileProps) {
  const {
    item, 
    selected = false, 
    onClick,
    onDoubleClick,
    'data-item-id': dataItemId,
  } = props

  const getIcon = () => {
    if (FileTypes.Image.includes(item?.mimeType)) {
      return item?.url
    } else if (item?.mimeType === FileTypes.Pdf) {
      return PdfIcon
    } else if (FileTypes.Doc.includes(item?.mimeType)) {
      return DocIcon
    } else if (FileTypes.Excel.includes(item?.mimeType)) {
      return ExcelIcon
    } else if (FileTypes.Powerpoint.includes(item?.mimeType)) {
      return PowerpointIcon
    } else if (item?.mimeType === FileTypes.Text) {
      return TextIcon
    }
    return null
  }

  const handleClick = (event: React.MouseEvent) => onClick?.(event)
  const handleDoubleClick = (event: React.MouseEvent) => onDoubleClick?.(event)

  const renderImage = () => {
    if (item?.type === 'image') {
      return <Box component='img' src={item?.url} sx={styles.image} />
    }
    return <Box component='img' src={getIcon()} sx={styles.icon} />
  }

  return (
    <ButtonBase
      sx={{
        ...styles.container,
        backgroundColor: selected ? Colors.selected : Colors.transparent,
        borderColor: selected ? Colors.transparent : Colors.secondary10,
      }}
      data-item-id={dataItemId}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      disableRipple
    >
      <Box sx={styles.imageContainer}>{renderImage()}</Box>
      <Box sx={styles.nameContainer}>
        <Typography variant='body2' sx={styles.name}>
          {item?.nameFi || item?.name || item?.originalName}
        </Typography>
        <Typography variant='body2' sx={styles.date}>
          {DateTime.fromISO(item?.updatedAt || item?.createdAt).toFormat('dd.MM.yyyy')}
        </Typography>
      </Box>
    </ButtonBase>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `0.125rem solid ${Colors.secondary10}`,
    borderRadius: '0.625rem',
    padding: '1.25rem 1.25rem 1rem 1.25rem',
    width: '13rem',
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Colors.secondary10,
    },
  },
  imageContainer: {
    width: '11.5rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: Colors.transparent,
    overflow: 'hidden',
    // borderRadius: '0.625rem',
    padding: 0,
    margin: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    // borderRadius: '0.625rem',
    backgroundColor: Colors.transparent,
    // Prevent image from interfering with selection
    pointerEvents: 'none',
  },
  icon: {
    height: '6rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none',
    pointerEvents: 'none',
  },
  nameContainer: {
    width: '10.5rem',
    m: '0.875rem 0rem 0rem 0rem',
  },
  name: {
    fontSize: '0.875rem',
    color: Colors.text,
    fontWeight: 600,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // Prevent text selection
    userSelect: 'none',
  },
  date: {
    fontSize: '0.875rem',
    color: Colors.text,
    fontWeight: 300,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // Prevent text selection
    userSelect: 'none',
  }
} as const
