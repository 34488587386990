import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import DefaultProfilePhoto from '../../Assets/Images/user.png'
import { Colors, Fonts } from '../../Utils/theme'
import { getNotificationActionDetails, getNotificationActionContent } from '../../Utils/transformers'

export default function NotificationItem(props: any) {
  const { t } = useTranslation()

  const { notification } = props

  const UserDetails: string = useMemo(() => {
    if (notification?.creatorUser) {
      return `${notification.creatorUser.firstName} ${notification.creatorUser.lastName}`?.trim()
    }
    return ''
  }, [notification])

  const ActionDetails = useMemo(() => {
    if (notification) {
      return getNotificationActionDetails(notification)
    }
    return ''
  }, [notification])

  const CreatedAt: string = useMemo(() => {
    if (notification?.createdAt) {
      const day = DateTime.fromISO(notification.createdAt).toFormat('dd.MM.yyyy')
      const time = DateTime.fromISO(notification.createdAt).toFormat('HH.mm')
      return `${day} ${t('at')} ${time}`
    }
    return ''
  }, [notification])

  const NotificationContent = useMemo(() => {
    if (notification) {
      const contentParts = getNotificationActionContent(notification)
      return (
        <Typography variant='body1' sx={styles.text}>
          {contentParts.map((part: any, index: number) => (
            <Box component='span' key={index}>
              {
                part?.type === 'highlight' ? (
                  <Box component='span' sx={styles.highlight}>{part.text} </Box>
                ) : `${part.text} `
              }
            </Box>
          ))}
        </Typography>
      )
    }
    return ''
  }, [notification])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.row}>
        <Avatar
          sx={styles.avatar}
          src={notification?.creatorUser?.profilePhoto || DefaultProfilePhoto}
          alt='photo'
        />
        <Box sx={styles.column}>
          <Typography variant='h5' sx={styles.title}>
            {UserDetails} {ActionDetails}
          </Typography>
          <Typography variant='body1' sx={styles.createdAt}>{CreatedAt}</Typography>
          <Box sx={styles.notification}>{NotificationContent}</Box>
        </Box>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.secondary}`,
    p: '1rem',
    mb: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  avatar: {
    width: '2.125rem',
    height: '2.125rem'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 'calc(100% - 2.125rem)',
    pt: '0.0625rem',
    ml: '0.75rem'
  },
  title: {
    fontFamily: Fonts.heading,
    color: Colors.heading,
    fontWeight: 700,
    fontSize: '0.875rem'
  },
  createdAt: {
    color: Colors.heading,
    fontWeight: 400,
    fontSize: '0.75rem'
  },
  notification: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: Colors.primary,
    borderRadius: '0.3125rem',
    p: '1rem',
    mt: '1rem'
  },
  text: {
    color: Colors.white,
    fontSize: '1rem'
  },
  highlight: {
    fontWeight: 700
  },
  backdrop: {
    backgroundColor: Colors.backdrop,
    zIndex: 1000
  }
} as const