import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from '..'
import UploadIcon from '../../Assets/Icons/file-upload.svg'
import { Colors } from '../../Utils/theme'

export default function FileUpload(props: any) {
  const { t } = useTranslation()

  const onUploadFiles = useCallback(async (files: any) => {
    if (files && files?.length) {
      props.onUploadFiles(files)
    }
  }, [])

  const drop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'text/plain': ['.txt']
    },
    multiple: true,
    noDrag: false,
    noClick: true,
    noKeyboard: true,
    onDrop: onUploadFiles
  })

  return (
    <Box
      sx={props?.dense ? styles.denseContainer : styles.container}
      {...drop.getRootProps()}
    >
      <input {...drop.getInputProps()} />
      <Box
        component='img'
        src={UploadIcon}
        sx={props?.dense ? styles.denseIcon : styles.icon}
      />
      <Typography variant='body2' sx={props?.dense ? styles.denseInfo : styles.info}>
        {t('drop_files_here')}
      </Typography>
      <Button
        text={props?.dense ? t('select') : t('select_files')}
        onClick={drop.open}
        width='100%'
        disabled={props?.loading}
        loading={props?.loading}
        sx={props?.dense ? styles.denseButton : styles.button}
      />
    </Box>
  )
}

const styles = {
  container: {
    // minWidth: '18rem',
    height: '18rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.625rem',
    border: `3px dashed ${Colors.primary40}`,
    p: '2rem'
  },
  denseContainer: {
    // minWidth: '18rem',
    height: '11rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: '0.625rem',
    border: `3px dashed ${Colors.primary40}`,
    p: '1.125rem'
  },
  icon: {
    height: '4rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none',
    mb: '0.75rem'
  },
  denseIcon: {
    height: '2.5rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none',
    mb: '0.5rem'
  },
  info: {
    fontSize: '1.125rem',
    color: Colors.primary,
    fontWeight: 500,
    mb: '2rem',
    textAlign: 'center'
  },
  denseInfo: {
    fontSize: '0.875rem',
    color: Colors.primary,
    fontWeight: 500,
    mb: '1rem',
    textAlign: 'center'
  },
  button: {
    fontSize: '1rem'
  },
  denseButton: {
    height: '2.375rem',
    fontSize: '1rem'
  }
} as const
